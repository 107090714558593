<template>
    <div class="doubleElection">
        <headLine msg='双选会'></headLine>   
        <div class="topBtn">
            <span>按条件查询：</span>
            <el-select
              v-model="statusUrl"
              placeholder="请选择" 
              clearable
              size="medium"
              @change="chose">
                <el-option
                v-for="item in options"
                :key="item.url"
                :label="item.name"
                :value="item.url">
                </el-option>
            </el-select>
            <span class="search">搜索：</span>
            <el-input v-model="dcName" placeholder="请输入双选会名称" style="width:250px" size="medium" clearable></el-input>
            <el-button type="primary" size="small" style="margin-left:10px" @click="chose">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            
            <el-table-column
            label="双选会时间"
            width="180"
            :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.startTime}}~{{scope.row.endTime}}</span>
                </template>
            </el-table-column>
            <el-table-column
            prop="dcName"
            width="160"
            label="双选会名称"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
            prop="sponsor"
            label="主办方"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
            prop="secSponsor"
            label="从办方"
            :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
            label="学校区域"
            :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span v-for="(v,i) in scope.row.orgAreas" :key="i">
                        {{v.name}} 
                    </span> 
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="学生规模">
                <template slot-scope="scope">
                    <span class="fontweight">
                       {{scope.row.orgIds?getorgNum(scope.row.orgIds):false}}个校区,
                       {{scope.row.stuNums}}个学生
                    </span> 
                </template>
            </el-table-column>
           
            <el-table-column label="操作" width="260">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    @click="lookDetail(scope.row)"
                   >查看详情</el-button>
                   <el-button
                    size="mini"
                    type="danger"
                    v-show="status==2"
                    @click="apply(scope.row.id)"
                   >我要申请
                   </el-button>
                   <el-button
                    size="mini"
                    type="danger"
                    v-show="status==3"
                    @click="applyAgain(scope.row.id)"
                   >再次申请
                   </el-button>
                   <el-tag
                   style="margin-left:10px"
                    v-show="status==1"
                    :type="scope.row.state?scope.row.state==2?'success':
                    scope.row.state==3?'danger':'info'
                    :''"
                    disable-transitions>
                    {{scope.row.state?scope.row.state==2?'可参加':
                    scope.row.state==3?'已公布':'已结束'
                    :''}}
                    </el-tag>
                   <el-tag
                   style="margin-left:10px"
                    v-show="status==3"
                    :type="scope.row.isPass?scope.row.isPass==2?'success':
                    scope.row.isPass==3?'danger':'info'
                    :''"
                    disable-transitions>
                    {{scope.row.isPass?scope.row.isPass==2?'已通过':
                    scope.row.isPass==3?'未通过':'审核中'
                    :''}}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <paging v-show="tableData.length>0" :totalpage="totalpage" @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        <el-dialog
            title="双选会详情"
            :visible.sync="doubleModel"
            width="38%"
            :closeOnClickModal="false"
            >
            <div class="doubleModel">
                <div class="attendedPosition" v-show="status==3">
                    已参与岗位：
                    <span v-for="(v,i) in detailText.zpJobs" :key="i">
                        {{v.jobName}}<span v-show="(i+1)!=detailText.zpJobs.length">、</span>
                    </span>
                </div>
                <ul>
                    <li>双选会名称：<span>{{detailText.dcName}}</span></li> 
                    <li>双选会时间：<span class="red">{{detailText.startTime}} ~ {{detailText.endTime}}</span></li> 
                    <li>双选会主办方：<span>{{detailText.sponsor}}</span></li> 
                    <li>双选会从办方：<span>{{detailText.secSponsor}}</span></li> 
                    <li>双选会规模：<span>{{detailText.stuNums}}(人)</span></li> 
                    <li>双选会区域：<span v-for="(v,i) in detailText.orgAreas" :key="i">
                        {{v.name}} 
                    </span> </li> 
                    <li>双选会内容：<span>{{detailText.doubleChooseIntroduce}}</span></li>     
                </ul>   
            </div>    
        </el-dialog>
        <el-dialog title="参与岗位" :visible.sync="joinPositionModel" width="500px" :closeOnClickModal="false">
            <div>
                <p class="joinP">请先选择参与双选会的岗位：</p>
                <div class="joinposition">
                     <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="margin: 8px 0;"></div>
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="(v,i) in positionList" :label="v.id" :key="i"
                        :value='v.id'>{{v.jobName}}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="joinPositionModel = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sureApply" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    data() {
        return {
            checkAll: false,
            checkedCities: [],
            isIndeterminate: true,
            dcName:'',//搜索
            positionList:[],
            joinPositionModel:false,
            tableData:[],
            options:[
                {url:'/hr/dc/all/list',name:'所有双选会',status:1},
                {url:'/hr/dc/can/list',name:'可申请双选会',status:2},
                {url:'/hr/dc/join/list',name:'已申请双选会',status:3}
            ],
            statusUrl:'/hr/dc/can/list',
            status:2, //默认的可申请的双选会
            doubleModel:false,
            detailText:{},
            totalpage:1,
            applyId:'',
            current:0
        }
    },
    methods: {
        //再次申请
        applyAgain(id){
            
            this.joinPositionModel=!this.joinPositionModel 
            this.applyId=id
        },
        //申请第一次
        apply(id){
           this.joinPositionModel=!this.joinPositionModel 
           this.applyId=id
        },
        //确定申请
        sureApply(){        
            let obj={
                doubleChooseId:this.applyId,
                jobIds: this.checkedCities.join()
            }
            if(!this.$allFun.checking(obj)) return
            let url=this.status==3?"/hr/dc/apply/again":"/hr/dc/apply"
            this.$htp.post_(url,obj,true).then(()=>{
                this.getInitList(this.current)  
                this.joinPositionModel=!this.joinPositionModel 
            })     
        },
        getorgNum(orgIds){
            return orgIds.split(',').length
        },
        handleCheckAllChange(val) {
            let arrId=[]
            this.positionList.forEach(({id})=>{
                arrId.push(id)
            })
            this.checkedCities = val ? arrId : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.positionList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.positionList.length;
        },
        
        lookDetail(row){
            this.doubleModel=!this.doubleModel
            this.detailText=row
        },
        chose(){
            if(this.statusUrl){
                this.options.forEach(e=>{
                    if(e.url==this.statusUrl){
                        this.status=e.status
                    }
                })
                this.getInitList(0)
            } 
        },  
        getInitList(current=0){
            let url=`${this.statusUrl}?page=${current}${this.dcName?"&dcName="+this.dcName:''}`
            this.$htp.get_(url).then((res)=>{ 
                this.tableData=res.data.rows
                this.totalpage=res.data.total 
            }) 
        },
        handle(val){
            this.current=val
        }, 
        getPositionList(){
            this.$htp.get_('/hr/job/all?companyId='+this.comId).then((res)=>{
                this.positionList=res.data      
            }) 
        }
    },
    mounted(){
        this.comId=sessionStorage.getItem('id')
        this.getInitList(0)
        this.getPositionList()
        
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/doubleElection.scss';
</style>
<style>
.doubleElection .el-table thead,.doubleElection .el-table th{
    background-color: #f2f6fc !important;
}
</style>